<template>
  <div class="container">
    <div v-if="pageDataLoaded === true">
      <h2 class="page-title mb4">
        <oc-content :item="$oc.content.termsOfUsePageTitle" />
      </h2>
      <oc-content :item="$oc.content.termsOfUsePageBody" />
    </div>
  </div>
</template>

<script>
import { getOrchardCoreContentModule } from '../lib/api'
import pageMixin from '../lib/mixins/page'

export default {
  name: 'Terms',
  mixins: [pageMixin],
  async mounted () {
    try {
      const response = await getOrchardCoreContentModule('terms-of-use-page')
      this.$oc.setContent(response.data)
      this.pageDataLoaded = true
    } catch {
      this.pageDataLoaded = false
    }
  },
  metaInfo: {
    title: 'Benefit Buddy - Terms of Use'
  }
}
</script>

<style>

</style>
